<template>
  <div>
    <el-drawer
      size="1048px"
      :title="infoDetail.parkName ? infoDetail.parkName : '停车场详细信息'"
      :visible.sync="showFlag"
      custom-class="pic-detail"
      @close="
        showFlag = false;
        $emit('close');
      "
    >
      <div class="content">
        <el-tabs v-model.trim="activeName">
          <el-tab-pane label="停车记录信息" name="first">
            <div class="parkInfo parkRecordInfo">
              <h2 class="parkingTitle">停车场信息</h2>
              <div class="parkInfoDetail">
                <div class="infoItem">
                  <span class="infoLabel">停车场名称</span
                  ><span>{{ infoDetail.parkName }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">区域</span
                  ><span>{{ infoDetail.areaName }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">商户</span
                  ><span>{{ infoDetail.operationName }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">车场类型</span
                  ><span>{{ infoDetail.parkType | parkTypeName }}</span>
                </div>
              </div>
            </div>
            <div class="parkInfo parkRecordInfo">
              <div class="parkingTitle">停车信息</div>
              <div class="parkInfoDetail">
                <div class="infoItem">
                  <span class="infoLabel">车牌号</span
                  >{{
                    /无/g.test(infoDetail.plateNumber)
                      ? "无牌车"
                      : infoDetail.plateNumber
                  }}
                </div>
                <div class="infoItem">
                  <span class="infoLabel">车辆组</span
                  ><span>{{ groupTypeList[infoDetail.groupType - 1] }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">会员手机号</span
                  ><span>{{ infoDetail.memberMobile }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">入场时间</span
                  ><span>{{ infoDetail.strEntryTime }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">出场时间</span
                  ><span
                    :style="{
                      color: infoDetail.strExitTime ? 'black' : 'red',
                    }"
                    >{{
                      infoDetail.strExitTime
                        ? infoDetail.strExitTime
                        : "等待出场..."
                    }}</span
                  >
                </div>
                <div class="infoItem">
                  <span class="infoLabel">停车时长</span
                  >{{ infoDetail.parkTime }}
                </div>
                <div class="infoItem">
                  <span class="infoLabel">订单金额</span
                  >{{
                    infoDetail.shouldPay
                      ? infoDetail.shouldPay / 100 + "元"
                      : "0元"
                  }}
                </div>
                <div class="infoItem">
                  <span class="infoLabel">商家余额折扣</span
                  >{{
                    infoDetail.storeDiscountMoney
                      ? infoDetail.storeDiscountMoney / 100 + "元"
                      : "-"
                  }}
                </div>
                <div class="infoItem" v-show="infoDetail.entryPdaManager">
                  <span class="infoLabel">入场操作人</span
                  >{{ infoDetail.entryPdaManager }}
                </div>
                <div class="infoItem" v-show="infoDetail.exitPdaManager">
                  <span class="infoLabel">出场操作人</span
                  ><span>{{ infoDetail.exitPdaManager }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">停车卡抵扣</span
                  >{{
                    infoDetail.parkCardMoney
                      ? infoDetail.parkCardMoney / 100 + "元"
                      : "-"
                  }}
                  <span v-if="infoDetail.parkCardName"
                    >({{ infoDetail.parkCardName }})</span
                  >
                </div>
                <div class="infoItem">
                  <span class="infoLabel">车场折扣</span
                  >{{
                    infoDetail.discountMoney
                      ? infoDetail.discountMoney / 100 + "元"
                      : "-"
                  }}
                  <span
                    v-if="infoDetail.discountRate || infoDetail.discountName"
                    >({{ infoDetail.discountRate + "%" }},{{
                      infoDetail.discountName
                    }})</span
                  >
                </div>
                <div class="infoItem">
                  <span class="infoLabel">应收金额</span
                  ><span>{{
                    infoDetail.shouldPayMoney
                      ? infoDetail.shouldPayMoney / 100 + "元"
                      : "0元"
                  }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">优惠券金额</span>
                  <span>{{
                    infoDetail.agioPay ? infoDetail.agioPay / 100 + "元" : "0元"
                  }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">优惠总计</span
                  >{{
                    infoDetail.totalDiscount
                      ? infoDetail.totalDiscount / 100 + "元"
                      : "-"
                  }}
                </div>
                <div class="infoItem">
                  <span class="infoLabel">实付金额</span
                  ><span>{{
                    infoDetail.actualPay
                      ? infoDetail.actualPay / 100 + "元"
                      : "0元"
                  }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">优惠退款</span
                  >{{
                    infoDetail.refundAgioMoney
                      ? infoDetail.refundAgioMoney / 100 + "元"
                      : "-"
                  }}
                </div>
                <div class="infoItem">
                  <span class="infoLabel">实付退款</span
                  ><span>{{
                    infoDetail.refundMoney
                      ? infoDetail.refundMoney / 100 + "元"
                      : "-"
                  }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">退款总计</span
                  ><span>{{
                    infoDetail.refundMoney + infoDetail.refundAgioMoney
                      ? (
                          (infoDetail.refundMoney +
                            infoDetail.refundAgioMoney) /
                          100
                        ).toFixed(2) + "元"
                      : "-"
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 入场 -->
            <div v-if="entryPic.length" class="parkInfo parkRecordInfo">
              <h2 class="parkingTitle">入场照片</h2>
              <div class="parkInfoDetail">
                <div
                  :class="[
                    'picBox',
                    value.imageType == 1 ? 'width100' : 'width33',
                  ]"
                  v-for="value in entryPic"
                  :key="value.accessKey"
                  v-show="value.imageType != 22"
                >
                  <div class="title">
                    {{
                      value.imageType == 1
                        ? "全景照片"
                        : value.imageType == 2
                        ? "车辆照片"
                        : value.imageType == 3
                        ? "车牌照片"
                        : ""
                    }}
                  </div>
                  <div class="pic">
                    <div
                      @click="showBigPic(value.picUrl)"
                      v-if="value.imageType == 1"
                      :style="{
                        width: '100%',
                        height: '100%',
                        background:
                          'white url(' +
                          value.picUrl +
                          ') center/contain no-repeat',
                      }"
                    ></div>
                    <img
                      v-if="value.imageType != 1"
                      :src="value.picUrl"
                      width="100%"
                      height="100%"
                      @click="showBigPic(value.picUrl)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- 出场 -->
            <div v-if="exitPic.length" class="parkInfo">
              <h2 class="parkingTitle">出场照片</h2>
              <div class="parkInfoDetail">
                <div
                  :class="[
                    'picBox',
                    value.imageType == 1 ? 'width100' : 'width33',
                  ]"
                  v-for="value in exitPic"
                  :key="value.accessKey"
                  v-show="value.imageType != 22"
                >
                  <div class="title">
                    {{
                      value.imageType == 1
                        ? "全景照片"
                        : value.imageType == 2
                        ? "车辆照片"
                        : value.imageType == 3
                        ? "车牌照片"
                        : ""
                    }}
                  </div>
                  <div class="pic">
                    <div
                      @click="showBigPic(value.picUrl)"
                      v-if="value.imageType == 1"
                      :style="{
                        width: '100%',
                        height: '100%',
                        background:
                          'white url(' +
                          value.picUrl +
                          ') center/contain no-repeat',
                      }"
                    ></div>
                    <img
                      v-if="value.imageType != 1"
                      :src="value.picUrl"
                      width="100%"
                      height="100%"
                      @click="showBigPic(value.picUrl)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="支付记录" name="second">
            <el-table :data="tableOrderdata" style="width: 100%">
              <el-table-column
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                :prop="item.value"
                :label="item.label"
                align="center"
                :width="item.width"
                v-for="item in tableOrder"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="退款订单" name="pageData">
            <el-table :data="parkRecordIdData" style="width: 100%">
              <el-table-column
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                :prop="item.value"
                :label="item.label"
                align="center"
                :width="item.width"
                v-for="item in parkRecordIdTable"
                v-if="item.value != 'actualExitTime' || showParkRecord"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            v-if="operationRecordData.length"
            label="操作记录"
            name="operationRecord"
          >
            <el-table :data="operationRecordData" style="width: 100%">
              <el-table-column
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                :prop="item.value"
                :label="item.label"
                align="center"
                :width="item.width"
                v-for="item in operationRecordTable"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            label="关联内场记录"
            name="relationRecord"
            v-if="showRelationRecord == 1"
          >
            <el-table :data="relationRecordData" style="width: 100%">
              <el-table-column
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                :prop="item.value"
                :label="item.label"
                align="center"
                :width="item.width"
                v-for="item in relationRecordTable"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane
            label="计费明细"
            v-if="Object.keys(recordChargeInfo).length"
            name="recordChargeInfo"
          >
            <div class="parkInfo">
              <h2 class="parkingTitle">计费规则</h2>
              <div class="parkInfoDetail">
                <div class="infoItem">
                  <span class="infoLabel">多车月卡计费规则</span
                  ><span>先到车默认为月租车</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">订单生效时间</span
                  ><span>{{ recordChargeInfo.orderTime }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">临停车计费规则</span
                  ><span>{{ recordChargeInfo.feeName }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">计费规则描述</span
                  ><span>{{ recordChargeInfo.feeDesc }}</span>
                </div>
              </div>
            </div>
            <div class="parkInfo">
              <h2 class="parkingTitle">计费信息</h2>
              <div class="parkInfoDetail">
                <div class="infoItem">
                  <span class="infoLabel">共享泊位数</span
                  ><span>{{ recordChargeInfo.berthNum }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">绑定车辆数</span
                  ><span>{{ recordChargeInfo.carNum }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">进场时次序</span
                  ><span>{{ recordChargeInfo.monthRanking }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">计费时长</span
                  ><span>{{ recordChargeInfo.parkTime }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">计费开始时间</span
                  ><span>{{ recordChargeInfo.monthFeeStartDateStr }}</span>
                </div>
                <div class="infoItem">
                  <span class="infoLabel">计费结束时间</span
                  ><span>{{ recordChargeInfo.monthFeeEndDateStr }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="chargeOrderList.length"
            label="充电订单"
            name="chargeOrder"
          >
            <charge-order :chargeOrderList="chargeOrderList"></charge-order>
          </el-tab-pane>
          <el-tab-pane
            v-if="chargeRecordList.length"
            label="充电违规记录"
            name="chargeRecord"
          >
            <charge-record :chargeRecordList="chargeRecordList"></charge-record>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>
<script>
import chargeOrder from "./chargeOrder";
import chargeRecord from "./chargeRecord";
export default {
  name: "picDetail",
  data() {
    return {
      showParkRecord: false,
      activeName: "first",
      bigPic: false,
      picUrl: "",
      defaultImg: "car-pic.png",
      page: 1,
      pageSize: 15,
      groupTypeList: ["免费车", "临时车", "军警车", "月租车"],
    };
  },
  filters: {
    parkTypeName(parkType) {
      let parkTypeList = {
        1: "路侧平行",
        2: "路侧垂停车场",
        3: "封闭车场",
        4: "半封闭车场",
      };
      return parkTypeList[parkType];
    },
  },
  watch: {
    parkRecordIdData: {
      handler(curVal, oldVal) {
        if (curVal.length > 0) {
          this.showParkRecord =
            this.parkRecordIdData[0].refundReasonName == "出场延时";
        }
      },
      deep: true,
    },
    rowData: {
      handler(curVal, oldVal) {
        this.activeName = "first";
      },
      immediate: true,
    },
  },
  mounted() {},
  computed: {
    showFlag: {
      get() {
        return this.detailFlag;
      },
      set() {},
    },
  },
  methods: {
    // 格式化日期
    dateFormat(date, fmt) {
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    closeFun() {
      this.$emit("close");
      this.activeName = "first";
    },
    handleCurrentChange(val) {
      this.$emit("getOrder", val);
    },
    showBigPic(url) {
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
  },
  props: {
    rowData: {
      default: () => {
        return {};
      },
    },
    recordChargeInfo: {
      default: function () {
        return {};
      },
    },
    infoDetail: {
      default: function () {
        return {};
      },
    },
    infoSize: {
      type: Number,
      default: 6,
    },
    total: {
      type: Number,
      default: 0,
    },
    tableOrderdata: {
      default: function () {
        return [];
      },
    },
    parkRecordIdData: {
      default: function () {
        return [];
      },
    },
    operationRecordData: {
      default: function () {
        return [];
      },
    },
    showRelationRecord: {
      default: 0,
    },
    relationRecordData: {
      default: function () {
        return [];
      },
    },
    tableOrder: {
      default: function () {
        return {};
      },
    },
    parkRecordIdTable: {
      default: function () {
        return {};
      },
    },
    operationRecordTable: {
      default: function () {
        return {};
      },
    },
    relationRecordTable: {
      default: function () {
        return {};
      },
    },
    exitPic: {
      default: function () {
        return [];
      },
    },
    entryPic: {
      default: function () {
        return [];
      },
    },
    detailFlag: {
      default: false,
    },
    chargeRecordList: {
      default: function () {
        return [];
      },
    },
    chargeOrderList: {
      default: function () {
        return [];
      },
    },
  },
  components: {
    chargeRecord,
    chargeOrder,
  },
  created() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  .el-pagination {
    text-align: right;
    padding-top: 20px;
  }
}

/deep/ .pic-detail {
  .el-drawer__header {
    padding: 12px 20px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    color: #1D2129;

    .el-drawer__close-btn {
      .el-dialog__close {
        font-size: 24px;
        color: #4e5969;
      }
    }
  }

  .el-drawer__body {
    .el-tabs__header {
      padding: 0px 20px;
      border: 1px solid #e5e6eb;
      border-left: none;
      border-right: none;
      background: #fff;
      position: sticky;
      top: 0;
      z-index: 1;

      .el-tabs__nav-wrap::after {
        display: none;
      }

      .el-tabs__nav {
        padding: 11px 0;
      }
    }

    .el-tabs__content {
      padding: 0 20px 20px;

      .el-collapse-item {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: -1px;
        }
      }

      .el-collapse-item__header {
        padding-left: 20px;
        background: #E2E9F3;
      }

      .parkInfo {
        background: #fff;
        padding: 0 20px;

        &.parkRecordInfo {
          margin-bottom: 16px;
        }

        .parkingTitle {
          font-weight: bold;
          font-size: 14px;
          color: #1D2129;
          padding-left: 13px;
          position: relative;
          height: 42px;
          line-height: 42px;
          margin-bottom: 8px;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 14px;
            width: 4px;
            height: 15px;
            background: #0F6EFF;
          }
        }

        .parkInfoDetail {
          display: flex;
          flex-wrap: wrap;

          .infoItem {
            width: 33.33%;
            color: #1d2129;
            margin-bottom: 28px;

            &.red {
              color: red;
            }

            .infoLabel {
              color: #86909C;
              width: 120px;
              display: inline-block;
              text-align: right;
              margin-right: 10px;
            }
          }
        }

        .imgList {
          margin-bottom: 16px;

          h2 {
            color: #1F2D3D;
            margin-bottom: 10px;
          }

          img {
            width: 180px;
            height: 150px;
            margin-right: 20px;
          }
        }

        .chargeRecordId {
          color: #86909C;
          text-align: center;
          margin-bottom: 20px;
          position: relative;

          &::before {
            content: '';
            width: 398px;
            border: 1px dashed #E5E6EB;
            top: 13px;
            left: 0;
            position: absolute;
          }

          &::after {
            content: '';
            width: 398px;
            border: 1px dashed #E5E6EB;
            top: 13px;
            right: 0;
            position: absolute;
          }
        }

        .orderId {
          color: #1F2D3D;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}

.content {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background: #F7F8FA;

  .picBox {
    float: left;
    width: 220px;
    padding: 0 20px 15px 0;
    box-sizing: border-box;
  }

  .title {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #8091A5;
    letter-spacing: 0;
    line-height: 16px;
  }

  .pic {
    background: url('car-pic.png') center no-repeat;
    width: 100%;
    height: 125px;
    border: 1px solid #8091A5;
    margin-top: 10px;
    overflow: hidden;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.width100 {
  width: 100% !important;
}
</style>
